@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-primary: #edab30;
  --bg-secondary: #ffffff;

  --blue: #3c66c4;
  --red: #cf4332;

  --bg-primary: #e09030;
  --bg-secondary: #fbfbfb;
  --bg-tertiary: #eaeaea;
  --bg-quad: #cacaca;

  --clr-primary: #000;
  --clr-secondary: #111;
  --clr-tertiary: #333;
  --lg-screen: 1474px;
  --xl-screen: 1860px;

  // babyboom
  --bg-primary-babyboom: #1FA3B4;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
hr {
  border: 1px solid #d9d9d9;
  margin: 1em 0;
  width: 100%;
}
.shadow-1 {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 3px 3px rgba(0, 0, 0, 0.11), 0 4px 6px rgba(0, 0, 0, 0.11),
    0 6px 12px rgba(0, 0, 0, 0.11);
}
.shadow-2 {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.05);
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(6px, 0, 0);
  }
}
#nprogress .bar {
  height: 3px;
}
#nprogress .spinner-icon {
  width: 25px;
  height: 25px;
  border: solid 3px transparent;
}
